<template>
  <v-dialog v-model="dialog" :max-width="500" :style="{ zIndex: 100000 }" @keydown.esc="cancel">
    <v-card class="pa-4">
      <v-list dense>
        <v-list-item-group v-model="nuevoEstado">
          <v-list-item
            v-for="estado,i in estados"
            :key="i"
            @click="nuevoEstado = estado.idEstado"
          >
            <v-chip class="v-chip--active" outlined label :color="estado.color">
              <v-icon left>{{ estado.icon }}</v-icon>
              {{ estado.nombreEstado }}
            </v-chip>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-divider></v-divider>

      <div class="d-flex ma-4" v-if="nuevoEstado">
        <v-chip
          class="v-chip--active"
          outlined
          label
          :color="nuevoEstado.color"
        >
          <v-icon left>{{ nuevoEstado.icon }}</v-icon>
          {{ nuevoEstado.nombreEstado }}
        </v-chip>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined @click.stop="cambiar">Confirmar</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      estados: [],
      nuevoEstado: null,
    };
  },
  methods: {
    getEstados() {
      return new Promise(async (resolve) => {
        const { data } = await axios(`/opciones/estados`);
        this.estados = data;
        resolve();
      });
    },
    open() {
      this.dialog = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.resolve(this.nuevoEstado)
      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    }
  },
  mounted() {
    this.getEstados();
  },
};
</script>

<style>
</style>